<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="9" class="d-flex justify-start align-center"><h2>Produk</h2></v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-btn color="primary" small depressed to="/product/add">Tambah</v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <h5>Filter</h5>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field label="Cari" outlined dense v-model="search" :hide-details="true" />
            </v-col>
            <v-col cols="4">
              <v-select
                :hide-details="true"
                :items="statuses"
                outlined
                dense
                item-text="name"
                item-value="value"
                v-model="selectedStatus"
                label="Status"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :hide-details="true"
                :items="categories"
                outlined
                dense
                item-text="name"
                item-value="id"
                v-model="selectedCategory"
                label="Kategori Produk"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" small @click="getData" depressed>Filter</v-btn>
          <v-btn
            color="primary"
            small
            depressed
            outlined
            class="ml-3"
            @click="
              search = ''
              selectedStatus = ''
              selectedCategory = ''
              getData()
            "
            >Reset</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="data"
        :options.sync="options"
        :server-items-length="total"
        :loading="loadingTable"
        class="elevation-1"
        :items-per-page="limit"
        :footer-props="{
          'items-per-page-options': [5, 10, 50, 100],
        }"
      >
        <template v-slot:[`item.option`]="{ item }">
          <v-row>
            <v-col cols="12">
              <v-btn color="warning" x-small class="mr-3" :to="`/product/edit/${item.id}`">Ubah</v-btn>
              <v-btn
                color="error"
                x-small
                @click="
                  deleteDialog = true
                  selectedDelete = item
                "
                >Hapus</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ item.status == 1 ? 'Aktif' : item.status == 2 ? 'Coming Soon' : 'Tidak Aktif' }}
        </template>
        <template v-slot:[`item.thumbnail`]="{ item }">
          <v-img :src="item.thumbnail" style="width: 100px"></v-img>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ formatRupiah(item.price) }}
        </template>
      </v-data-table>
    </v-col>

    <v-snackbar v-model="alert.status" :timeout="timeout" :color="alert.success ? 'success' : 'error'" right bottom>
      {{ alert.message }}
    </v-snackbar>

    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title>Yakin dihapus?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              color="error"
              small
              depressed
              class="mr-2"
              :loading="loadingDeleteButton"
              :disabled="loadingDeleteButton"
              @click="deleteData"
              >Ya</v-btn
            >
            <v-btn color="error" outlined depressed small @click="deleteDialog = false">Tidak</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical, mdiRefresh, mdiFileOutline } from '@mdi/js'
import axios from 'axios'
import { formatRupiah } from '@/utils/index'

export default {
  setup() {
    return {
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiRefresh,
        mdiFileOutline,
      },
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'))
    },
  },
  data() {
    return {
      alert: {
        status: false,
        success: false,
        message: '',
      },
      timeout: 1200,
      loadingTable: false,
      total: 0,
      data: [],
      limit: 10,
      search: '',
      statuses: [
        { name: 'Pilih Semua', value: '' },
        { name: 'Aktif', value: 1 },
        { name: 'Tidak Aktif', value: 0 },
        { name: 'Coming Soon', value: 2 },
      ],
      selectedStatus: '',
      headers: [
        { text: '#', value: 'thumbnail', sortable: false },
        { text: 'Nama', value: 'name', sortable: false },
        { text: 'Code', value: 'code', sortable: false },
        { text: 'Harga', value: 'price', sortable: false },
        { text: 'Kategori', value: 'category.name', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Opsi?', value: 'option', sortable: false },
      ],
      options: {},
      formData: {
        id: '',
        name: '',
        status: 1,
        seo_url: '',
      },
      selectedDelete: null,
      deleteDialog: false,
      loadingDeleteButton: false,
      categories: [],
      selectedCategory: null,
      loadingCategory: false,
    }
  },
  mounted() {
    this.getData()
    this.getCategories()
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  methods: {
    getData() {
      this.loadingTable = true
      this.data = []
      const token = localStorage.getItem('token')
      const { page, itemsPerPage } = this.options
      const params = {
        page,
        limit: itemsPerPage,
        q: this.search,
        status: this.selectedStatus ?? '',
        product_category_id: this.selectedCategory ?? ''
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}product?${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.loadingTable = false
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.total = data.data.count
              this.data = data.data.rows
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.loadingTable = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    setAlert(value) {
      this.alert = value
    },
    deleteData() {
      this.loadingDeleteButton = true
      const token = localStorage.getItem('token')
      axios
        .delete(`${process.env.VUE_APP_API_ENDPOINT}product/${this.selectedDelete.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.loadingDeleteButton = false
          this.deleteDialog = false
          if (res.status == 200) {
            this.alert = {
              status: true,
              success: res.data.success,
              message: res.data.message,
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
          this.getData()
        })
        .catch(err => {
          this.loadingDeleteButton = false
          this.deleteDialog = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    formatRupiah(value) {
      return formatRupiah(value)
    },
    getCategories() {
      this.loadingCategory = true
      this.categories = []
      const token = localStorage.getItem('token')
      const params = {
        page: 1,
        limit: 99,
        q: '',
        status: '',
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}product-category?${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.loadingCategory = false
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.categories = [
                {
                  id: '',
                  name: 'Pilih Semua',
                },
                ...data.data.rows,
              ]
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.loadingCategory = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
  },
}
</script>

<style>
</style>