var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"9"}},[_c('h2',[_vm._v("Produk")])])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","small":"","depressed":"","to":"/product/add"}},[_vm._v("Tambah")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('h5',[_vm._v("Filter")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Cari","outlined":"","dense":"","hide-details":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"hide-details":true,"items":_vm.statuses,"outlined":"","dense":"","item-text":"name","item-value":"value","label":"Status"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"hide-details":true,"items":_vm.categories,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"Kategori Produk"},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":"","depressed":""},on:{"click":_vm.getData}},[_vm._v("Filter")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","small":"","depressed":"","outlined":""},on:{"click":function($event){_vm.search = ''
            _vm.selectedStatus = ''
            _vm.selectedCategory = ''
            _vm.getData()}}},[_vm._v("Reset")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loadingTable,"items-per-page":_vm.limit,"footer-props":{
        'items-per-page-options': [5, 10, 50, 100],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.option",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"warning","x-small":"","to":("/product/edit/" + (item.id))}},[_vm._v("Ubah")]),_c('v-btn',{attrs:{"color":"error","x-small":""},on:{"click":function($event){_vm.deleteDialog = true
                _vm.selectedDelete = item}}},[_vm._v("Hapus")])],1)],1)]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == 1 ? 'Aktif' : item.status == 2 ? 'Coming Soon' : 'Tidak Aktif')+" ")]}},{key:"item.thumbnail",fn:function(ref){
                var item = ref.item;
return [_c('v-img',{staticStyle:{"width":"100px"},attrs:{"src":item.thumbnail}})]}},{key:"item.price",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatRupiah(item.price))+" ")]}}],null,true)})],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.alert.success ? 'success' : 'error',"right":"","bottom":""},model:{value:(_vm.alert.status),callback:function ($$v) {_vm.$set(_vm.alert, "status", $$v)},expression:"alert.status"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Yakin dihapus?")]),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","small":"","depressed":"","loading":_vm.loadingDeleteButton,"disabled":_vm.loadingDeleteButton},on:{"click":_vm.deleteData}},[_vm._v("Ya")]),_c('v-btn',{attrs:{"color":"error","outlined":"","depressed":"","small":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Tidak")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }